import styled from "styled-components";
import { Link } from "react-router-dom";

export const Title = styled.h2`
  font-size: 2.5rem; /* Larger font size for titles */
  color: #fff; /* White text color */
  margin-bottom: 1rem;
  text-align: left; /* Align text to the left */

  @media only screen and (max-width: 768px) {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
`;

export const FooterSection = styled.footer`
  background-color: #000; /* Set background to pure black */
  color: #fff; /* White text color */
  padding: 3rem 1rem; /* Increased padding for better spacing */
  text-align: center;
  border-top: 2px solid #4caf50; /* Add a green border to separate the section */

  @media only screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top for better structure */
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center; /* Center items on smaller screens */
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const NavLink = styled(Link)`
  color: #4caf50; /* Green color for links */
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer; /* Ensure links are clearly clickable */

  &:hover {
    color: #ff9800; /* Orange on hover */
  }

  @media only screen and (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem; /* Add space above social icons */

  a {
    color: #4caf50; /* Green color for social icons */
    margin: 0 0.5rem;
    font-size: 1.5rem;
    cursor: pointer; /* Ensure social icons are clearly clickable */

    &:hover {
      color: #ff9800; /* Orange on hover */
    }
  }
`;

export const Para = styled.p`
  color: #fff;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: left; /* Align text to the left */

  @media only screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const Large = styled(Link)`
  font-size: 16px;
  color: #4caf50; /* Green color for links */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  max-width: max-content;
  text-decoration: none; /* Ensure links are clearly clickable */

  &:hover {
    color: #ff9800; /* Orange on hover */
    text-underline-position: under;
    text-decoration: #ff9800 wavy underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

export const Chat = styled("p")`
  color: #4caf50; /* Green color for chat link */
  max-width: fit-content;
  border-bottom: 1px solid #4caf50; /* Matching the text color */
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px solid #ff9800; /* Orange on hover */
    color: #ff9800;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const Extra = styled("section")`
  background: #000; /* Match footer background */
  position: relative;
  width: 100%;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  border-top: 2px solid #4caf50; /* Add a green border to match the top */

  @media screen and (max-width: 768px) {
    padding: 0; /* Remove padding for smaller screens */
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #4caf50; /* Primary green color */
  display: block;
  margin-bottom: 2rem;
  font-family: "Motiva Sans Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
    font-size: 18px; /* Adjust font size for smaller screens */
  }
`;

export const Language = styled.h2`
  font-size: 2.5rem; /* Match the title font size */
  color: #fff; /* White text color */
  margin-bottom: 1rem;
  text-align: left; /* Align text to the left */

  @media only screen and (max-width: 768px) {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
`;

export const LanguageSwitch = styled("div")`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;

  @media screen and (max-width: 768px) {
    justify-content: center; /* Center language switches on smaller screens */
    width: 100%; /* Adjust width on smaller screens */
  }
`;

export const Copyright = styled.p`
  color: #fff; /* White text color */
  font-size: 1rem;
  margin: 0;
  text-align: right;

  @media only screen and (max-width: 768px) {
    text-align: center; /* Center text on smaller screens */
    margin-top: 1rem; /* Add margin for spacing */
  }
`;