import styled from "styled-components";

export const StyledContainer = styled("div")<{
  border?: boolean;
}>`
  position: relative;
  width: 100%; /* Ensure full width */
  max-width: 100%; /* Ensure full width on larger screens */
  margin-right: auto;
  margin-left: auto;
  padding: 0; /* Ensure no padding */
  border-top: ${(p) => (p.border ? "1px solid #E0E0E0" : "")}; /* Light Gray border */

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
    padding: 0;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 38px);
    padding: 0; /* Ensure no padding */
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0; /* Ensure no padding */
  }
`;
