import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "../../common/Button"; // Import the Button component

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  background-color: #000; /* Set header background to pure black */

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  text-decoration: none; /* Ensure no underline */
  outline: none; /* Remove focus outline */
  
  &:hover,
  &:focus {
    outline: none; /* Remove any outline on hover or focus */
    box-shadow: none; /* Remove any box-shadow */
    text-decoration: none; /* Ensure no underline */
  }
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #4CAF50; /* Changed to primary green for consistency */
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #000; /* Set menu text color to pure black */
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold; /* Make text bold */
  color: #4CAF50; /* Primary green color */
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  &:hover,
  &:active,
  &:focus {
    color: #FF9800; /* Change to theme orange on hover */
    text-underline-position: under;
    text-decoration: #FF9800 wavy underline;
  }

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #000; /* Set label text color to pure black */
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
  color: #4CAF50; /* Primary green color for consistency */
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #FF9800; /* Set hover color to theme orange */
    text-underline-position: under;
    text-decoration: #FF9800 wavy underline;
  }
`;

export const ContactButton = styled.button`
  padding: 0.75rem 2rem; /* Adjusted padding for better size */
  font-size: 1.5rem !important; // Use !important to override other styles
  border: none !important; // Use !important to ensure no border
  color: #fff; /* White text color */
  background-color: #4caf50; /* Green background color */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9800; /* Orange on hover */
  }

  @media only screen and (max-width: 768px) {
    padding: 0.6rem 1.5rem; /* Adjust padding for smaller screens */
  }
`;

export const MenuWrapper = styled("div")`
  background-color: #000 !important; /* Ensure the menu background is pure black */
  // ... other styles ...
`;
