import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  ContactButton, // Import the new styled component
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const closeDrawer = () => {
    setVisibility(false);
  };

  const handleContactClick = () => {
    if (location.pathname === "/") {
      // Scroll to contact section if already on the home page
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Navigate to home page and then scroll to contact section
      navigate("/#contact");
    }
    closeDrawer();
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall as={Link} to="/about" onClick={closeDrawer}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall as={Link} to="/mission" onClick={closeDrawer}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          as="a"
          href="https://ghost.forminty.com"
          rel="noopener noreferrer"
          onClick={closeDrawer}
        >
          <Span>{t("Blog")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall as="a" onClick={handleContactClick} style={{ width: "180px" }}>
          <Span>
            <ContactButton>{t("Contact")}</ContactButton> {/* Use the new styled component */}
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer as={Link} to="/" aria-label="homepage">
            <SvgIcon src="logo.png" height="64px" width="auto" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer
          closable={false}
          open={visible}
          onClose={toggleButton}
          bodyStyle={{ backgroundColor: '#000' }} // Set the drawer body background to black
        >
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem /> {/* Close menu when a link is clicked */}
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
