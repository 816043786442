import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  SocialIcons,
  Copyright, // Import the new styled component
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Contact")}</Title>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="https://t.me/forminty">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Company")}</Title>
              <Large to="/about">{t("About")}</Large>
              <Large to="/blog">{t("Blog")}</Large>
              <Large to="/press">{t("Press")}</Large>
              <Large to="/careers">{t("Careers & Culture")}</Large>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Policy")}</Title>
              <Large to="/">{t("Refund Policy")}</Large>
              <Large to="/">{t("Support System")}</Large>
              <Large to="/dayana">{t("Meet Dayana")}</Large>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Address")}</Title>
              <Para>Toronto</Para>
              <Para>Canada</Para>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Language")}</Title>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Title>{t("Follow Us")}</Title>
              <SocialIcons>
                <SocialLink
                  href="https://facebook.com/forminty"
                  src="facebook.svg"
                />
                <SocialLink
                  href="https:/youtube.com/forminty"
                  src="youtube.svg"
                />
                <SocialLink
                  href="https:/pinterest.com/forminty"
                  src="pinterest.svg"
                />
                <SocialLink
                  href="https://t.me/forminty"
                  src="telegram.svg"
                />
                <SocialLink
                  href="https://wa.me/1234567890"
                  src="whatsapp.svg"
                />
                <SocialLink
                  href="https://x.com/forminty"
                  src="x.svg"
                />
              </SocialIcons>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "0.5rem" }}
          >
            <Col>
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="logo.png"
                    aria-label="homepage"
                    width="auto%"
                    height="64px"
                  />
                </LogoContainer>
              </NavLink>
            </Col>
            <Col>
              <Copyright>© 2023 - Present, Forminty.com</Copyright>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);